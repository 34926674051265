<template>
  <div class="share-container relative flex flex-col items-center justify-center">
    <div class="absolute z-10 top-6 left-1/2 transform -translate-x-1/2 cursor-pointer" @click="goToEnigma()">
      <img src="../assets/icons/close.svg" alt="">
    </div>
    <share-experience theme="dark"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShareExperience from '@/components/ShareExperience.vue'
export default {
  name: 'share',
  components: {  
    ShareExperience
  },
  computed: {
    ...mapGetters({
      lastEnigmaId: "user/lastEnigmaId"
    })
  },
  methods: {
    goToEnigma() {
      if (this.$route.params.fromName) {
        this.$router
          .push({ name: this.$route.params.fromName })
          .catch(console.log)
      } else {
        this.$router
          .push({ name: 'hub', params: { swiperIndex: this.lastEnigmaId || '1' } })
          .catch(console.log)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .share-container {
    background: url('../assets/bg/share-found-min.jpg');
    background-size: cover;
    height: 100vh;
    @media screen and (min-width: 768px) {
      background: url('../assets/bg/share-found.jpg');
      background-size: cover;
    }
  }
</style>