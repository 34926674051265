<template>
  <div class="text-center" :class="{'text-black':theme === 'light'}">
    <p>
      <span class="font-sangbleukingdom uppercase" :class="{'text-quinary': theme === 'dark', 'text-primary': theme === 'light'}">Share the experience</span><br>Invite your friends to discover the experience
    </p>
    <section class="flex flex-wrap py-4 justify-evenly virals">
      <!-- FACEBOOK -->
      <div>
        <img class="cursor-pointer" @click="shareFb(); trackClick(linkNumbers.facebook)" :src="theme === 'dark' ? FacebookDarkICon : FacebookLightICon" alt="facebook icon">
      </div>

      <!-- WHATSAPP -->
      <div>
        <img v-if="typeOfDevice === 'mobile'" class="cursor-pointer" @click="shareWhatsapp(); trackClick(linkNumbers.whatsapp)" :src="theme === 'dark' ? WhatsappDarkICon : WhatsappLightICon" alt="whatsapp icon">
      </div>

      <!-- MESSENGER -->
      <div v-if="typeOfDevice === 'mobile'">
        <img v-if="typeOfDevice === 'mobile'" class="cursor-pointer" @click="shareMessenger(); trackClick(linkNumbers.messenger)" :src="theme === 'dark' ? MessengerDarkICon : MessengerLightICon" alt="messenger icon">
      </div>
      
      <!-- EMAILS -->
      <div>
        <img class="cursor-pointer" @click="trackClick(5); updateModalConfig({ isOpen: true, modalComponent: 'DiffusionEmailsModal' })" :src="theme === 'dark' ? EmailDarkICon : EmailLightICon" alt="email icon">
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { isProdEnv, getDeviceType } from "../helpers";
import design from '@/validators/design';
import EmailDarkICon from '../assets/icons/email-dark.svg';
import EmailLightICon from '../assets/icons/email-light.svg';
import FacebookDarkICon from '../assets/icons/facebook-dark.svg';
import FacebookLightICon from '../assets/icons/facebook-light.svg';
import MessengerDarkICon from '../assets/icons/messenger-dark.svg';
import MessengerLightICon from '../assets/icons/messenger-light.svg';
import WhatsappDarkICon from '../assets/icons/whatsapp-dark.svg';
import WhatsappLightICon from '../assets/icons/whatsapp-light.svg';
export default {
  name: 'ShareExperience',
  props: {
    theme: {
      type: String,
      required: true,
      default: 'dark'
    }
  },
  data() {
    return {
      EmailDarkICon,
      EmailLightICon,
      FacebookDarkICon,
      FacebookLightICon,
      MessengerDarkICon,
      MessengerLightICon,
      WhatsappDarkICon,
      WhatsappLightICon,

      colorStyle: design.colors,
      panoplyStyle: design.panoply,
      typeOfDevice: getDeviceType(),
    };
  },
  computed: {
    ...mapGetters({
      decliConfig: "config/activeDecliConfig",
      user: "user/user",
      token: "global/token",
      idu: "user/idu",
      visitId: "global/visitId",
      appConfig: "config/appConfig",
    }),

    idfroms() {
      return this.decliConfig.idfroms;
    },
    linkNumbers() {
      return this.decliConfig.linkNumbers;
    },
  },
  methods: {
    ...mapActions(["updateModalConfig"]),
    shareTracking(platform) {
      console.log('this.$route.name', this.$route.name)
      if (this.$route.name === 'congratulations') {
        this.$gtmTracking.shareActionSuccess(platform)
      } else {
        this.$gtmTracking.shareActionPopin(platform)
      }
    },
    shareFb() {
      this.$route.name === 'congratulations' ? this.shareTracking('Facebook') : this.shareTracking('Facebook')
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.getShareUrl(this.idfroms.facebook))}`, '_blank')
    },
    shareWhatsapp() {
      this.$route.name === 'congratulations' ? this.shareTracking('Whatsapp') : this.shareTracking('Whatsapp')
      window.location.href = `whatsapp://send?text=${this.$t(
        "diffusion.share.whatsappDescription"
      )} ${encodeURIComponent(this.getShareUrl(this.idfroms.whatsapp))}`;
    },
    shareMessenger() {
      this.$route.name === 'congratulations' ? this.shareTracking('Messenger') : this.shareTracking('Messenger')
      window.location.href = `fb-messenger://share/?link=${encodeURIComponent(
        this.getShareUrl(this.idfroms.messenger)
      )}&app_id=${
        isProdEnv()
          ? this.decliConfig.facebook.appIds.prod
          : this.decliConfig.facebook.appIds.staging
      }, _self`;
    },
    getShareUrl(idfrom) {
      return `${window.location.origin}/?idfrom=${idfrom}&idup=${this.idu}`;
    },
    trackClick(linkId) {
      const oTrackData = {
        iIdU: +this.idu,
        iPageId: 17,
        iLinkNumber: linkId,
        iVisitId: +this.visitId,
      };

      this.$requestAPI.trackPageClick(oTrackData)
        .catch(console.log);
    }
  }
}
</script>

<style lang="scss" scoped>
.virals {
  @media screen and (min-width: 1200px) {
    width: 160px;
    margin: auto;
  }
}
</style>